.form-control {
  text-align: right;
}

.calculator-footer{
  display: flex;
  align-items: center;
}

.thin-table {
    white-space: nowrap;
    width: 1% !important;
}
.thin-table td.fit,
.thin-table th.fit {
    white-space: nowrap;
    width: 1%;
}
